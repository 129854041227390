/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-psd': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-.5v-1h.5a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM3.116 14.841a1.13 1.13 0 00.401.823q.194.162.478.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 00.187-.656q0-.336-.135-.56a1 1 0 00-.375-.357 2 2 0 00-.565-.21l-.621-.144a1 1 0 01-.405-.176.37.37 0 01-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 01.245.181.56.56 0 01.12.258h.75a1.1 1.1 0 00-.199-.566 1.2 1.2 0 00-.5-.41 1.8 1.8 0 00-.78-.152q-.44 0-.776.15-.337.149-.528.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 01.153.326.5.5 0 01-.085.29.56.56 0 01-.255.193q-.167.07-.413.07-.176 0-.32-.04a.8.8 0 01-.248-.115.58.58 0 01-.255-.384zM1.6 11.932H0v4h.791v-1.343h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 00.161-.677q0-.375-.158-.677a1.2 1.2 0 00-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 01-.085.38.57.57 0 01-.238.241.8.8 0 01-.375.082H.788v-1.406h.66q.327 0 .512.182.185.181.185.521m4.609 2.666v-3.999h1.459q.609 0 1.004.234.396.235.59.68.195.445.195 1.075 0 .634-.196 1.084t-.594.689q-.396.237-1 .237H6.755zm1.353-3.354h-.562v2.707h.562q.279 0 .483-.082a.8.8 0 00.334-.252 1.1 1.1 0 00.197-.422q.067-.252.067-.592a2.1 2.1 0 00-.117-.753.9.9 0 00-.355-.454q-.236-.152-.609-.152"/>',
    },
});
